import { ErrorPage as ErrorPageComponent } from '@components/error/ErrorPage';
import { GetServerSidePropsContext } from 'next';

export default function ErrorPage({ statusCode }: { statusCode: number }) {
  return <ErrorPageComponent statusCode={statusCode} />;
}

ErrorPage.getInitialProps = ({
  res,
  err,
}: GetServerSidePropsContext & {
  err: { statusCode?: number };
}) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 500;

  return { statusCode };
};
